<template>
  <v-container fluid class="pa-0">
    <v-row no-gutters>
      <v-col sm="12">
        <HereMap ref="map" :center="center" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import HereMap from '../components/HereMap'
export default {
  name: "RouteMap",
  created() {
    //map points
    let from_lng_lat = this.mappoints.move_from.long_lat.split(",");
    this.start = {
      lat: parseFloat(from_lng_lat[1]),
      lng: parseFloat(from_lng_lat[0]),
    };
    let to_lng_lat = this.mappoints.move_to.long_lat.split(",");
    this.finish = {
      lat: parseFloat(to_lng_lat[1]),
      lng: parseFloat(to_lng_lat[0]),
    };    
  },
  mounted() {
    let map = this.$refs.map;
    map.drawRoute(this.start, this.finish);
  },
  methods: {
    currentOrderMapPoints() {
      const currentOrder = this.$store.getters.currentOrders.find((move) => {
        return move.movement_id == this.$route.params.mid;
      });
      return currentOrder.map_points;
    },     
  },
  computed: {},
  components: {
    HereMap,
  },
  data() {
    return {
      center:{ 
        lat: 45.508, 
        lng: -73.587
      }, 
      mappoints: this.currentOrderMapPoints(),
      start: "",
      finish: ""       
    };
  },
};
</script>
